.App {
  text-align: left;
  margin: 10px;
  padding: 10px;
  max-width: 1080px;

} 

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.DisplayMultiComponents {
  display: flex;
  margin: 0 10px;
}
.DisplayMultiComponents > * {
  flex: 1;
}
.ArtistInput {
  padding: 0 10px;
  margin: 0 10px;
}
.AddMoreButton {
  position: absolute;
}

.CloseButton {
  position: absolute;
  top: 10px !important;
  right: 20px !important;
 }